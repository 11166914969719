import { Controller } from 'stimulus';

class PDAssignmentController extends Controller {
  resetAssignments(event) {
    const confirmationText = `Resetting assignments will remove the assessment
      from the learner's account, your program director dashboard, and all group
      reports. If you need a copy of assessment performance, please download the
      Cohort Asessment Report before proceeding. The assessments will become
      available to assign again. This action cannot be undone. Would you like to
      continue?
    `.replace(/\s+/g, ' ');

    const result = confirm(confirmationText);
    const jwt = event.target.dataset.jwt;

    if (result) {
      const examInputs = document.getElementsByName(
        'exam_ids[]'
      ) as NodeListOf<HTMLInputElement>;

      const examIds = Array.from(examInputs)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value);

      const body = { examIds: examIds };

      fetch('/api/v3/assignment_resets', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then(() => location.reload());
    }
  }
}

export default PDAssignmentController;
